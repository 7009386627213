import { pagesList } from '../../constants/constants.js';

export function getMenuItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

export const menuList = [
  getMenuItem('Agenda Econômica', 'agenda_economica', null, [
    getMenuItem(pagesList.agendaDia.menuName, pagesList.agendaDia.slug),
    getMenuItem(pagesList.agendaSemana.menuName, pagesList.agendaSemana.slug),
  ]),
  getMenuItem(pagesList.bitcoinEthereum.menuName, pagesList.bitcoinEthereum.slug),
  getMenuItem(pagesList.ironOre.menuName, pagesList.ironOre.slug),
  getMenuItem(pagesList.petroleo.menuName, pagesList.petroleo.slug),
  getMenuItem(pagesList.agriculturalCommodities.menuName, pagesList.agriculturalCommodities.slug),
  getMenuItem(pagesList.jurosFuturos.menuName, pagesList.jurosFuturos.slug),
  getMenuItem(pagesList.ipca.menuName, pagesList.ipca.slug),
  getMenuItem(pagesList.mercadosGlobais.menuName, pagesList.mercadosGlobais.slug),
  getMenuItem(pagesList.metalCommodities.menuName, pagesList.metalCommodities.slug),
  getMenuItem(pagesList.marketClosure.menuName, pagesList.marketClosure.slug),
  getMenuItem(pagesList.mercadoCrypto.menuName, pagesList.mercadoCrypto.slug),
  getMenuItem(pagesList.nasdaq.menuName, pagesList.nasdaq.slug),
  getMenuItem(pagesList.americanIndexes.menuName, pagesList.americanIndexes.slug),
  getMenuItem('FED/COPOM', 'fed_copom', null, [
    getMenuItem(pagesList.superQuarta.menuName, pagesList.superQuarta.slug),
    getMenuItem(pagesList.taxaSelic.menuName, pagesList.taxaSelic.slug),
  ]),
  getMenuItem('Feed semanal', 'feed_semanal', null, [
    getMenuItem(pagesList.feedRise.menuName, pagesList.feedRise.slug),
    getMenuItem(pagesList.feedDrop.menuName, pagesList.feedDrop.slug),
    getMenuItem(pagesList.feedCurrency.menuName, pagesList.feedCurrency.slug),
  ]),
  getMenuItem('Notícias', 'news', null, [
    getMenuItem(pagesList.happeningNewsBrazil.menuName, pagesList.happeningNewsBrazil.slug),
    getMenuItem(pagesList.happeningNewsWorld.menuName, pagesList.happeningNewsWorld.slug),
    getMenuItem(pagesList.breakingNews.menuName, pagesList.breakingNews.slug),
  ]),
  getMenuItem('Montebravo', 'montebravo', null, [
    getMenuItem(pagesList.aberturaMercadoMontebravo.menuName, pagesList.aberturaMercadoMontebravo.slug),
    getMenuItem(pagesList.fechamentoMercadoMontebravo.menuName, pagesList.fechamentoMercadoMontebravo.slug),
  ]),
  getMenuItem(pagesList.focus.menuName, pagesList.focus.slug),
  getMenuItem(pagesList.jornal.menuName, pagesList.jornal.slug),
  getMenuItem(pagesList.minute.menuName, pagesList.minute.slug),
  getMenuItem(pagesList.noticiasCrypto.menuName, pagesList.noticiasCrypto.slug),
  getMenuItem(pagesList.twitter.menuName, pagesList.twitter.slug),
  getMenuItem(pagesList.fundRanking.menuName, pagesList.fundRanking.slug),
  getMenuItem(pagesList.FundosImobiliarios.menuName, pagesList.FundosImobiliarios.slug),
  getMenuItem(pagesList.formulaDrawing.menuName, pagesList.formulaDrawing.slug),
  getMenuItem(pagesList.temporadaBalancos.menuName, pagesList.temporadaBalancos.slug),
  getMenuItem(pagesList.tesouroDireto.menuName, pagesList.tesouroDireto.slug),
  getMenuItem(pagesList.tesouroDiretoFeed.menuName, pagesList.tesouroDiretoFeed.slug),
  getMenuItem(pagesList.taxaJurosEUA.menuName, pagesList.taxaJurosEUA.slug),
  getMenuItem(pagesList.fluxoInvestimentoB3.menuName, pagesList.fluxoInvestimentoB3.slug),
  getMenuItem(pagesList.imagemPersonalizada.menuName, pagesList.imagemPersonalizada.slug),
  getMenuItem('Assessores', 'assessores/listar'),
];